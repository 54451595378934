<template>
  <div class="rounded-md p-4 bg-lightest">
    <div class="mb-4">
      <TextListItem icon="fa-lightbulb" class="mb-2" label="Active"
        ><i v-if="automation.active" class="fa fa-check-circle text-success"></i><i v-else class="fa fa-times-circle text-danger"></i
      ></TextListItem>
      <TextListItem icon="fa-tag" class="text-capitalize mb-2" label="Category">{{ automation.category }}</TextListItem>
      <TextListItem icon="fa-list" class="text-capitalize mb-2" label="Source">{{ automation.source }}</TextListItem>
      <TextListItem icon="fa-conveyor-belt" class="text-capitalize mb-2" label="Service">{{ automation.service }}</TextListItem>
      <TextListItem icon="fa-timer" class="text-capitalize mb-2" label="Frequency">{{ automation.frequency }}</TextListItem>
    </div>

    <div class="mb-3">
      <div class="d-flex justify-content-between">
        <label class="text-capitalize">{{ automation.service }} specific params</label>

        <button v-if="$auth.isAdmin" class="btn btn-primary btn-sm mb-2" :disabled="disableRunManually" @click="onClickRunManually">
          Run Manually
        </button>
      </div>

      <div class="bg-white rounded-md p-3">
        <pre>{{ automation.data }}</pre>
      </div>
    </div>

    <div class="mb-3">
      <label>Last Collection Information</label>
      <TextListItem icon="fa-clock" class="mb-2" label="Timestamp">{{
        automation.lastCollection?.collectedAt | date('Do MMM YYYY HH:mm:ss')
      }}</TextListItem>
      <TextListItem icon="fa-tag" class="text-capitalize mb-2" label="Status">{{ automation.lastCollection?.status }}</TextListItem>
      <TextListItem icon="fa-circle-exclamation" class="text-capitalize mb-2" label="Error">{{ automation.lastCollection?.error }}</TextListItem>
    </div>

    <div>
      <label>Last Collected Data Sample</label>
      <div v-if="Object.keys(automation.lastCollection?.data || {}).length > 0" class="bg-white rounded-md p-3">
        <pre>{{ automation.lastCollection?.data }}</pre>
      </div>
      <div v-else class="alert alert-warning mb-0">No Data</div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import TextListItem from '@/components/base/TextListItem';

export default {
  name: 'AutomationInfo',
  components: {
    TextListItem
  },
  props: {
    automation: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      disableRunManually: false
    };
  },
  methods: {
    ...mapActions({
      runAutomation: 'automation/runAutomation'
    }),
    async onClickRunManually() {
      this.disableRunManually = true;

      const response = await this.runAutomation({ id: this.automation._id });

      if (response.invoked) {
        this.$toasted.success('Automation run successfully!', { position: 'bottom-right', duration: 3000 });
      } else {
        this.$toasted.danger('Automation did not run', { position: 'bottom-right', duration: 3000 });
      }

      this.disableRunManually = false;
    }
  }
};
</script>
